import React from 'react';
import {CssBaseline} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import 'reset-css';
import {ToastContainer} from 'react-toastify';
import {TranslationsProvider} from '@vidiemme/react-i18n';
import 'react-toastify/dist/ReactToastify.css';
import {AppointmentAttachmentsProvider} from './contexts/appointmentAttachments';
import LocalizationProviderMUI from './contexts/LocalizationProviderMUI';
import {Navigation} from './navigation';
import reportWebVitals from './reportWebVitals';
import {initializeStore} from './store/app';
import theme from './themes';
import {en} from './translations';
import './hooks/toast/Toast.scss';

if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
  Sentry.init({
    release: process.env.npm_package_version,
    environment: process.env.REACT_APP_ENVIRONMENT,

    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const store = initializeStore();

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <TranslationsProvider
            params={{
              resources: {
                en: {
                  translation: en,
                },
              },
            }}>
            <DndProvider backend={HTML5Backend}>
              <LocalizationProviderMUI>
                <AppointmentAttachmentsProvider>
                  <Navigation />
                </AppointmentAttachmentsProvider>
              </LocalizationProviderMUI>
            </DndProvider>
            <ToastContainer />
          </TranslationsProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
);

if (process.env.REACT_APP_WEB_VITALS === 'true') {
  reportWebVitals();
}
