import {
  AuthenticationAction,
  AuthenticationActions,
  AuthenticationState,
} from './types.d';

export const getInitialState = (): AuthenticationState => ({
  isFetched: false,
  isFetching: false,
  token: undefined,
  refreshToken: undefined,
  msToken: undefined,
});

export const authenticationReducer = (
  state = getInitialState(),
  action: AuthenticationAction,
) => {
  if (action.type === AuthenticationActions.loginLoading) {
    return {
      ...state,
      isFetching: true,
      error: undefined,
    };
  }
  if (action.type === AuthenticationActions.loginSuccess) {
    return {
      ...state,
      isFetched: true,
      isFetching: false,
      error: undefined,
      token: action.payload.token,
      refreshToken: action.payload.refreshToken,
      msToken: action.payload.msToken,
    };
  }
  if (action.type === AuthenticationActions.loginFail) {
    return {
      ...state,
      isFetching: false,
      error: action.payload,
    };
  }
  if (action.type === AuthenticationActions.logout) {
    return getInitialState();
  }
  return state;
};
