import React, {Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import {LoaderSplash} from '../../components/atoms/LoaderSplash';

const Loadable = () => {
  return (
    <Suspense fallback={<LoaderSplash />}>
      <Outlet />
    </Suspense>
  );
};

export default React.memo(Loadable);
