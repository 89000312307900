import React from 'react';
import {Navigate, Outlet} from 'react-router';
import {LoaderSplash} from '../../components/atoms/LoaderSplash';
import {useUser} from '../../store/user/hooks';
import {AuthRoutes} from '../routes';

const WithoutRole = () => {
  const {userRole, isLoading} = useUser();

  if (isLoading) {
    return <LoaderSplash />;
  }

  if (userRole === null) {
    return <Outlet />;
  }

  return <Navigate to={AuthRoutes.MAIN} replace />;
};

export default React.memo(WithoutRole);
