import {useCallback, useMemo} from 'react';
import * as Sentry from '@sentry/react';
import {useQuery} from '@tanstack/react-query';
import {
  Account,
  GetManagersResponse,
  GetUserResponse,
  GetUsersParams,
  GetUsersResponse,
  User,
  UserRole,
} from '../../definitions/Users';
import {buildQuery} from '../../utils/buildQuery';
import {getFullName} from '../../utils/getFullName';
import {useAPI} from '../api';
import {useAuthentication} from '../authentication';

/**
 * Hooks for handling users
 */
export const useUser = () => {
  const {api} = useAPI();

  const {isFetching} = useAuthentication();

  /** Get user */
  const getUser = useCallback(
    async (id: number) => {
      try {
        const data = await api.get<GetUserResponse>(`/users/${id}`);

        return data;
      } catch (error) {
        console.error('[Get User]', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [api],
  );

  /** Get all user */
  const getUsers = useCallback(
    async (params?: GetUsersParams) => {
      try {
        const query = buildQuery({...params});
        const data = await api.get<GetUsersResponse>(`/users${query}`);

        return data;
      } catch (error) {
        console.error('[Get Users]', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [api],
  );

  /** Get all managers */
  const getManagers = useCallback(async () => {
    try {
      const data = await api.get<GetManagersResponse>('/users/managers');
      return data;
    } catch (error) {
      console.error('[Get Managers]', error);
      Sentry.captureException(error);
      throw error;
    }
  }, [api]);

  /** Add new user */
  const addUser = useCallback(
    async (payload: User) => {
      try {
        const data = await api.post('/users', payload);

        return data;
      } catch (error) {
        console.error('[Add User]', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [api],
  );

  /** Update user */
  const updateUser = useCallback(
    async ({id, payload}: {id: number; payload: User}) => {
      try {
        const data = await api.put(`/users/${id}`, payload);

        return data;
      } catch (error) {
        console.error('[Update User]', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [api],
  );

  /** Delete a user */
  const deleteUser = useCallback(
    async (id: number) => {
      try {
        const data = await api.delete(`/users/${id}`);

        return data;
      } catch (error) {
        console.error('[Delete User]', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [api],
  );

  /** Get current user */
  const getCurrentUser = useCallback(async () => {
    try {
      const data = await api.get<Account>('/user/me');

      return data;
    } catch (error) {
      console.error('[Get Current User]', error);
      Sentry.captureException(error);
      throw error;
    }
  }, [api]);

  const {data: userResponse, isLoading} = useQuery(
    ['users', 'me'],
    getCurrentUser,
    {
      enabled: isFetching === false,
    },
  );
  const user = useMemo(() => userResponse?.data, [userResponse?.data]);

  const userId = user?.id;

  /** Current user role */
  const userRole = user?.role;

  const isShowroomManager = userRole === UserRole.SHOWROOM_MANAGER;

  const isSalesManager = userRole === UserRole.SALES_MANAGER;

  const isManager = isShowroomManager || isSalesManager;

  const isReceptionist = userRole === UserRole.RECEPTIONIST;

  const isSeller = userRole === UserRole.SELLER;

  const isBuyer = userRole === UserRole.BUYER;

  const hasNoRole = userRole === null;

  /** Current user name */
  const userName = getFullName(user?.first_name, user?.last_name);

  /** Current user email */
  const userEmail = user?.email;

  /** Current user phone number */
  const userPhoneNumber = user?.phone_number;

  /** Current user store name */
  const storeName = user?.store_name;

  return {
    getUser,
    getUsers,
    addUser,
    updateUser,
    deleteUser,
    isLoading,
    userId,
    userRole,
    isShowroomManager,
    isSalesManager,
    isManager,
    isReceptionist,
    isSeller,
    isBuyer,
    hasNoRole,
    userName,
    userEmail,
    userPhoneNumber,
    storeName,
    getManagers,
  };
};
