import React, {useMemo} from 'react';
import 'dayjs/locale/it';
import type {PropsWithChildren} from 'react';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useTranslations} from '@vidiemme/react-i18n';

const LocalizationProviderMUI = ({children}: PropsWithChildren) => {
  const {t} = useTranslations();

  const dateFormats = useMemo(
    () => ({
      fullDate: t('DatePickerMUI.dateFormat'),
      keyboardDate: t('DatePickerMUI.dateFormat'),
    }),
    [t],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={dateFormats}>
      {children}
    </LocalizationProvider>
  );
};

export default React.memo(LocalizationProviderMUI);
