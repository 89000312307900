import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {LoaderSplash} from '../../components/atoms/LoaderSplash';
import {useAuthentication} from '../../store/authentication';
import {AnonymousRoutes} from '../routes';
import WithRole, {WithRoleProps as AuthenticatedProps} from './WithRole';

const Authenticated = ({requiredRoles = []}: AuthenticatedProps) => {
  const location = useLocation();

  const {isLoggedIn, isFetching} = useAuthentication();

  if (isFetching) {
    return <LoaderSplash />;
  }

  if (!isLoggedIn) {
    return (
      <Navigate to={AnonymousRoutes.LOGIN} state={{from: location}} replace />
    );
  }

  return <WithRole requiredRoles={requiredRoles} />;
};

export default React.memo(Authenticated);
