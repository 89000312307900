import React, {createContext, useContext, useMemo, useState} from 'react';
import {Attachments} from '../../store/appointment/interfaces';
import {
  AppointmentAttachmentsContextProps,
  AppointmentAttachmentsProviderProps,
  FileWithPreview,
} from './definitions';

const AppointmentAttachmentsContext =
  createContext<AppointmentAttachmentsContextProps>({
    attachmentsToAdd: [],
    setAttachmentsToAdd: () => {},
    attachments: [],
    setAttachments: () => {},
    attachmentIdsToRemove: [],
    setAttachmentIdsToRemove: () => {},
  });

export const AppointmentAttachmentsProvider = ({
  children,
}: AppointmentAttachmentsProviderProps) => {
  const [attachmentsToAdd, setAttachmentsToAdd] = useState<FileWithPreview[]>(
    [],
  );
  const [attachments, setAttachments] = useState<Attachments[]>([]);
  const [attachmentIdsToRemove, setAttachmentIdsToRemove] = useState<number[]>(
    [],
  );

  const memoizedValue = useMemo<AppointmentAttachmentsContextProps>(
    () => ({
      attachmentsToAdd,
      setAttachmentsToAdd,
      attachments,
      setAttachments,
      attachmentIdsToRemove,
      setAttachmentIdsToRemove,
    }),
    [attachmentIdsToRemove, attachments, attachmentsToAdd],
  );

  return (
    <AppointmentAttachmentsContext.Provider value={memoizedValue}>
      {children}
    </AppointmentAttachmentsContext.Provider>
  );
};

export const useAppointmentAttachmentsContext =
  (): AppointmentAttachmentsContextProps =>
    useContext(AppointmentAttachmentsContext);
