import {combineReducers} from 'redux';
import {AuthenticationActions, authenticationReducer} from '../authentication';

export const rootReducer = combineReducers({
  authentication: authenticationReducer,
});

export const appReducer = (state: any, action: any) => {
  if (action.type === AuthenticationActions.loginSuccess) {
    return rootReducer(
      {
        ...state,
        authentication: state.authentication,
      },
      action,
    );
  }
  return rootReducer(state, action);
};
