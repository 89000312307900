import React from 'react';
import {Box} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';
import {ReactComponent as Logo} from '../../../themes/assets/logo.svg';
import {Text} from '../Text';
import {useStyles} from './styles';

const LoaderSplash = () => {
  const {t} = useTranslations();
  const styles = useStyles();

  return (
    <Box sx={styles.backdrop}>
      <Box sx={styles.container}>
        <Box sx={styles.image}>
          <Logo />
        </Box>
        <Text variant="headline1" sx={styles.text}>
          {t('Generic.appName')}
        </Text>
      </Box>
    </Box>
  );
};

export default React.memo(LoaderSplash);
