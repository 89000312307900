import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {LoaderSplash} from '../../components/atoms/LoaderSplash';
import {UserRole} from '../../definitions/Users';
import {useUser} from '../../store/user/hooks';
import {AuthRoutes} from '../routes';

export interface WithRoleProps {
  requiredRoles?: UserRole[];
}

const WithRole = ({requiredRoles = []}: WithRoleProps) => {
  const {userRole, isLoading} = useUser();

  const noRolesRequired = requiredRoles.length === 0;
  const validRole = !!userRole && requiredRoles?.includes(userRole);

  if (isLoading) {
    return <LoaderSplash />;
  }

  if (noRolesRequired || validRole) {
    return <Outlet />;
  }

  return <Navigate to={AuthRoutes.MAIN} replace />;
};

export default React.memo(WithRole);
