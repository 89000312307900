import {useMemo} from 'react';
import {Theme} from '@mui/material';
import {StyleProps} from './interfaces';

export const useStyles = ({
  bold,
  italic,
  uppercase,
  disabled,
  underline,
}: StyleProps) => {
  const text = useMemo(
    () => ({
      fontWeight: (theme: Theme) =>
        bold
          ? theme.typography.fontWeightBold
          : theme.typography.fontWeightRegular,
      fontStyle: italic ? 'italic' : 'normal',
      textTransform: uppercase ? 'uppercase' : 'initial',
      textDecoration: underline ? 'underline' : 'initial',
      color: (theme: Theme) => (disabled ? theme.palette.gray4 : 'unset'),
    }),
    [bold, disabled, italic, underline, uppercase],
  );

  return {text};
};
