import {AuthenticationState} from './types.d';

export const selectorIsLoggedIn = ({
  authentication,
}: {
  authentication: AuthenticationState;
}) => Boolean(authentication.token);

export const selectorToken = ({
  authentication,
}: {
  authentication: AuthenticationState;
}) => authentication.token;

export const selectorRefreshToken = ({
  authentication,
}: {
  authentication: AuthenticationState;
}) => authentication.refreshToken;

export const selectorMsToken = ({
  authentication,
}: {
  authentication: AuthenticationState;
}) => authentication.msToken;

export const selectorError = ({
  authentication,
}: {
  authentication: AuthenticationState;
}) => authentication.error;

export const selectorIsFetching = ({
  authentication,
}: {
  authentication: AuthenticationState;
}) => authentication.isFetching;
