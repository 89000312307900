import React, {useCallback} from 'react';
import {Slide, toast, ToastOptions} from 'react-toastify';
import {Text} from '../../components/atoms/Text';
import {IProps} from './interfaces';

const commonProps: ToastOptions = {
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  transition: Slide,
  position: toast.POSITION.TOP_CENTER,
  closeButton: false,
};

export const useToast = () => {
  /**
   * @brief Show error
   */
  const showErrorToast = useCallback(({message}: IProps) => {
    toast.error(
      <span className="Toastify__icon-container">
        <Text variant="headline4">{message}</Text>
      </span>,
      {
        ...commonProps,
      },
    );
  }, []);

  /**
   * @brief Show warning
   */
  const showWarningToast = useCallback(({message}: IProps) => {
    toast.warning(
      <span className="Toastify__icon-container">
        <Text variant="headline4">{message}</Text>
      </span>,
      {
        ...commonProps,
      },
    );
  }, []);

  /**
   * @brief Show message
   */
  const showSuccessToast = useCallback(({message}: IProps) => {
    toast.success(
      <span className="Toastify__icon-container">
        <Text variant="headline4">{message}</Text>
      </span>,
      {
        ...commonProps,
      },
    );
  }, []);

  return {
    showErrorToast,
    showWarningToast,
    showSuccessToast,
  };
};
