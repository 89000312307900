import {useCallback} from 'react';
import * as Sentry from '@sentry/react';
import {useQueryClient} from '@tanstack/react-query';
import {useDispatch, useSelector} from 'react-redux';
import {_api as api} from '../api';
import {LoginFail, LoginLoading, LoginSuccess, Logout} from './actions';
import {
  selectorError,
  selectorIsFetching,
  selectorIsLoggedIn,
  selectorMsToken,
  selectorRefreshToken,
  selectorToken,
} from './selectors';
import {AuthenticationResponse, AuthenticationState} from './types.d';

export const useAuthentication = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const isLoggedIn = useSelector<
    {authentication: AuthenticationState},
    boolean
  >(selectorIsLoggedIn);

  const token = useSelector<
    {authentication: AuthenticationState},
    string | undefined
  >(selectorToken);

  const refreshTokenFromStore = useSelector<
    {authentication: AuthenticationState},
    string | undefined
  >(selectorRefreshToken);

  const msToken = useSelector<
    {authentication: AuthenticationState},
    string | undefined
  >(selectorMsToken);

  const error = useSelector<
    {authentication: AuthenticationState},
    string | undefined
  >(selectorError);

  const isFetching = useSelector<
    {authentication: AuthenticationState},
    boolean
  >(selectorIsFetching);

  const saveAuth = useCallback(
    ({token, refreshToken, msToken}: AuthenticationResponse) => {
      try {
        localStorage.setItem('auth', token);
        localStorage.setItem('auth_refresh', refreshToken);
        localStorage.setItem('ms_token', msToken);
      } catch (e) {
        Sentry.captureException(e);
        throw e;
      }
    },
    [],
  );

  const getAuth = useCallback(() => {
    try {
      const token = localStorage.getItem('auth');
      const refreshToken = localStorage.getItem('auth_refresh');
      const msToken = localStorage.getItem('ms_token');
      if (token && refreshToken && msToken) {
        dispatch(LoginSuccess({token, refreshToken, msToken}));
      }
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
  }, [dispatch]);

  const removeAuth = useCallback(() => {
    try {
      localStorage.removeItem('auth');
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
  }, []);

  const login = useCallback(
    async (code: string) => {
      try {
        dispatch(LoginLoading());
        const {data} = await api.post('/auth/get_token', {
          code,
          called_by_localhost:
            window.location.origin.startsWith('http://localhost'),
        });
        const {
          access_token: msToken,
          refresh_token: refreshToken,
          id_token: token,
        } = data;
        saveAuth({token, refreshToken, msToken});
        dispatch(LoginSuccess({token, refreshToken, msToken}));
      } catch (error) {
        if (error instanceof Error) {
          dispatch(LoginFail(error));
          Sentry.captureException(error);
          throw error;
        }
      }
    },
    [dispatch, saveAuth],
  );

  const logout = useCallback(() => {
    try {
      removeAuth();
      dispatch(Logout());
      // Clear all TanStack Query caches
      queryClient.clear();
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }, [removeAuth, dispatch, queryClient]);

  const refreshLogin = useCallback(async () => {
    try {
      dispatch(LoginLoading());
      const {data} = await api.post('/auth/refresh_token', {
        refresh_token: refreshTokenFromStore,
      });
      const {
        access_token: msToken,
        refresh_token: refreshToken,
        id_token: token,
      } = data;
      saveAuth({token, refreshToken, msToken});
      dispatch(LoginSuccess({token, refreshToken, msToken}));
    } catch (error) {
      logout();
      if (error instanceof Error) {
        dispatch(LoginFail(error));
        Sentry.captureException(error);
        throw error;
      }
    }
  }, [dispatch, logout, refreshTokenFromStore, saveAuth]);

  return {
    isLoggedIn,
    token,
    refreshToken: refreshTokenFromStore,
    msToken,
    error,
    login,
    refreshLogin,
    getAuth,
    isFetching,
    logout,
    removeAuth,
  };
};
