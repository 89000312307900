import {useMemo} from 'react';
import {SxProps, Theme} from '@mui/material';

export const useStyles = () => {
  const container = useMemo<SxProps<Theme>>(
    () => ({
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: theme => theme.spacing(2),
    }),
    [],
  );

  const progress = useMemo<SxProps<Theme>>(
    () => ({color: theme => theme.palette.black}),
    [],
  );

  return {container, progress};
};
