import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {LoaderSplash} from '../../components/atoms/LoaderSplash';
import {useAuthentication} from '../../store/authentication';
import {AuthRoutes} from '../routes';

const Guest = () => {
  const {isLoggedIn, isFetching} = useAuthentication();

  if (isFetching) {
    return <LoaderSplash />;
  }

  if (isLoggedIn) {
    return <Navigate to={AuthRoutes.MAIN} replace />;
  } else {
    return <Outlet />;
  }
};

export default React.memo(Guest);
