import {AuthenticationAction, AuthenticationActions} from './types.d';

export const LoginLoading = (): AuthenticationAction => ({
  type: AuthenticationActions.loginLoading,
});

export const LoginSuccess = (data: {
  token: string;
  refreshToken: string;
  msToken: string;
}): AuthenticationAction => ({
  type: AuthenticationActions.loginSuccess,
  payload: data,
});

export const LoginFail = (error: Error): AuthenticationAction => ({
  type: AuthenticationActions.loginFail,
  payload: error,
});

export const Logout = (): AuthenticationAction => ({
  type: AuthenticationActions.logout,
});
