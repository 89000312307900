import React from 'react';
import Typography, {TypographyProps} from '@mui/material/Typography';
import {TextProps} from './interfaces';
import {useStyles} from './styles';

const variantMapping: TypographyProps['variantMapping'] = {
  headline1: 'h1',
  headline2: 'h2',
  headline3: 'h3',
  headline4: 'h4',
  headline5: 'h5',
  text: 'p',
  small: 'p',
  xsmall: 'p',
};

const Text = ({
  variant = 'text',
  bold,
  italic,
  uppercase,
  underline,
  disabled,
  children,
  sx = [],
  ...props
}: TextProps) => {
  const customSx = Array.isArray(sx) ? sx : [sx];
  const styles = useStyles({bold, italic, disabled, uppercase, underline});

  return (
    <Typography
      color="primary"
      sx={[styles.text, ...customSx]}
      variant={variant}
      variantMapping={variantMapping}
      {...props}>
      {children}
    </Typography>
  );
};

export default React.memo(Text);
