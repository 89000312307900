import React, {Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import {RouteLoader} from '../../components/atoms/RouteLoader';

const LoadableRoute = () => {
  return (
    <Suspense fallback={<RouteLoader />}>
      <Outlet />
    </Suspense>
  );
};

export default React.memo(LoadableRoute);
