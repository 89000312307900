import React from 'react';
import {Box, CircularProgress} from '@mui/material';
import {useStyles} from './styles';

const RouteLoader = () => {
  const styles = useStyles();
  return (
    <Box sx={styles.container}>
      <CircularProgress disableShrink sx={styles.progress} />
    </Box>
  );
};

export default React.memo(RouteLoader);
