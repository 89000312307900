interface IProps {
  [paramName: string]: string | number | boolean | string[] | undefined;
}

/**
 * @brief Function to build query params string
 */
export const buildQuery = (params: IProps) => {
  const stringedParams = Object.entries(params).reduce((acc, [key, value]) => {
    if (value === undefined) {
      return acc;
    }
    return {
      ...acc,
      [key]: value.toString(),
    };
  }, {});

  const query = new URLSearchParams(stringedParams);
  const queryToString = query.toString();

  return queryToString ? `?${queryToString}` : '';
};
